// Generated by ts-to-zod
import { z } from 'zod';
import { PaymentNoticeStatus, PaymentOptionStatus } from './data-contracts';

export const tokenResponseSchema = z.object({
  accessToken: z.string(),
  tokenType: z.string().default('Bearer'),
  expiresIn: z.number(),
  refreshToken: z.string().optional(),
  idToken: z.string().optional()
});

export const userInfoSchema = z.object({
  userId: z.string(),
  fiscalCode: z.string(),
  familyName: z.string().optional(),
  name: z.string().optional(),
  email: z.string().optional()
});

export const zendeskAssistanceTokenResponseSchema = z.object({
  assistanceToken: z.string(),
  returnTo: z.string(),
  actionUrl: z.string()
});

export const transactionDTOSchema = z.object({
  transactionId: z.string().optional(),
  payeeName: z.string().optional(),
  payeeTaxCode: z.string().optional(),
  amount: z.number().optional(),
  transactionDate: z.string().datetime().optional(),
  isCart: z.boolean().optional(),
  paidByMe: z.boolean().optional(),
  registeredToMe: z.boolean().optional()
});

export const walletInfoDTOSchema = z.object({
  accountHolder: z.string().optional(),
  brand: z.string().optional(),
  blurredNumber: z.string().optional(),
  maskedEmail: z.string().optional()
});

export const userDetailDTOSchema = z.object({
  name: z.string().optional(),
  taxCode: z.string().optional()
});

export const localDateTimeSchema = z.string().datetime();

export const paymentNoticeStatusSchema = z.nativeEnum(PaymentNoticeStatus);

export const localDateSchema = z.string();

export const paymentOptionStatusSchema = z.nativeEnum(PaymentOptionStatus);

export const errorDTOSchema = z.object({
  error: z.union([
    z.literal('generic_error'),
    z.literal('transaction_not_found_error'),
    z.literal('receipt_not_found_error'),
    z.literal('invalid_amount'),
    z.literal('invalid_date'),
    z.literal('invalid_email'),
    z.literal('invalid_request')
  ]),
  error_description: z.string().optional()
});

export const transactionsListDTOSchema = z.object({
  transactions: z.array(transactionDTOSchema).optional(),
  currentPage: z.number().optional(),
  itemsForPage: z.number().optional(),
  totalItems: z.number().optional(),
  totalPages: z.number().optional()
});

export const infoTransactionDTOSchema = z.object({
  transactionId: z.string().optional(),
  authCode: z.string().optional(),
  rrn: z.string().optional(),
  transactionDate: z.string().datetime().optional(),
  pspName: z.string().optional(),
  walletInfo: walletInfoDTOSchema.optional(),
  paymentMethod: z
    .union([
      z.literal('BBT'),
      z.literal('BP'),
      z.literal('AD'),
      z.literal('CP'),
      z.literal('PO'),
      z.literal('OBEP'),
      z.literal('JIF'),
      z.literal('MYBK'),
      z.literal('PPAL'),
      z.literal('UNKNOWN')
    ])
    .optional(),
  payer: userDetailDTOSchema.optional(),
  amount: z.number().optional(),
  fee: z.number().optional(),
  origin: z
    .union([
      z.literal('INTERNAL'),
      z.literal('PM'),
      z.literal('NDP001PROD'),
      z.literal('NDP002PROD'),
      z.literal('NDP003PROD'),
      z.literal('UNKNOWN')
    ])
    .optional()
});

export const cartItemDTOSchema = z.object({
  subject: z.string().optional(),
  amount: z.number().optional(),
  payee: userDetailDTOSchema.optional(),
  debtor: userDetailDTOSchema.optional(),
  refNumberValue: z.string().optional(),
  refNumberType: z.string().optional()
});

export const installmentDTOSchema = z.object({
  nav: z.string(),
  iuv: z.string(),
  paTaxCode: z.string(),
  paFullName: z.string(),
  amount: z.number(),
  description: z.string(),
  dueDate: localDateTimeSchema,
  retentionDate: localDateTimeSchema.optional(),
  insertedDate: localDateTimeSchema,
  notificationFee: z.number().optional(),
  status: paymentOptionStatusSchema,
  lastUpdatedDate: localDateTimeSchema
});

export const transactionDetailsDTOSchema = z.object({
  infoTransaction: infoTransactionDTOSchema.optional(),
  carts: z.array(cartItemDTOSchema).optional()
});

export const paymentOptionDTOSchema = z.object({
  description: z.string().optional(),
  numberOfInstallments: z.number(),
  amount: z.number().optional(),
  dueDate: localDateTimeSchema.optional(),
  isPartialPayment: z.boolean().optional(),
  switchToExpired: z.boolean(),
  installments: z.array(installmentDTOSchema)
});

export const paymentNoticeDTOSchema = z.object({
  iupd: z.string(),
  debtorTaxCode: z.string(),
  debtorFullName: z.string(),
  debtorType: z.string(),
  paTaxCode: z.string(),
  paFullName: z.string(),
  insertedDate: localDateTimeSchema,
  publishDate: localDateTimeSchema,
  validityDate: localDateTimeSchema,
  status: paymentNoticeStatusSchema,
  lastUpdateDate: localDateSchema,
  paymentOptions: z.array(paymentOptionDTOSchema)
});

export const paymentNoticesListDTOSchema = z.object({
  paymentNotices: z.array(paymentNoticeDTOSchema).optional()
});
